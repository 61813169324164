import React from 'react'

const Header = () => {
  return (
    <section className="header" id="home">
      <div className="header-banner">
        <h1>Hi, I am Michael</h1>
        <p>
          A Full Stack Developer, specializing in MERN stack. 
        </p>
        {/* <a href="#projects" className="btn header-btn">Projects</a> */}
      </div>
    </section>
  )
}

export default Header