import React from 'react'
import {links, externalLinks} from './subComponents/Links';
import PageLinks from './subComponents/PageLinks';

const Footer = () => {
  return (
     <footer className="section footer" id='contact'>
      <PageLinks parentClass='footer-links' itemClass='footer-link' />

      <ul className="footer-icons">
        {externalLinks.map((link)=>{
          const {id, href, icon} = link;

          return (
            <li key={id}>
              <a 
                href={href} 
                target="_blank" 
                rel="noreferrer"
                className="footer-icon"
              >
                {icon}
              </a>
            </li>
          );
        })}
      </ul>
      <p className="copyright">
        <span> Email: <a href="mailto:klinem@gmail.com">klinem@gmail.com</a> </span>
        
        <span id="date">Copyright &copy; Michael R. Kline {new Date().getFullYear()}, all rights reserved</span>
      </p>
    </footer>
  )
}

export default Footer