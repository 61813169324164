import React from 'react'
import { links } from './Links'

const PageLinks = ({parentClass, itemClass}) => {
  return (
   <ul className={parentClass} id="nav-links">
          {links.map((link)=>{
             const {id, href, text} = link;

            return (
              <li key={id}>
                <a href={href} className={itemClass}>{text}</a>
              </li>
            );
          })}
        </ul>
  )
}

export default PageLinks