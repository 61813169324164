import React from 'react';
import Titles from './subComponents/Titles';
import { technologies } from './subComponents/Technologies';

const Skills = () => {
  return (
     <section className="section skills" id="skills">
      <Titles title='skills' />

      <div className="section-center skills-center">
        {technologies.map((technology)=>{
          const {id, image, title} = technology;

          return(
            <article key={id} className="skill">
              
              <div className="skill-info">
                <img src={image} alt={title}/>
                <h4 className="skill-title">{title}</h4>
              </div>
              
            </article>
          )
        })}

      </div>
    </section>
  )
}

export default Skills