import React from 'react'
import Titles from './subComponents/Titles'
import { projects } from './subComponents/Links'

const Projects = () => {
  return (
    <section className="section projects" id="projects">
      <Titles title='projects' />

      <div className="section-center projects-center">
        {projects.map((project)=>{
          const {id, title, image, href, codeHref, text} = project;

          return(
            
            <article key={id} className="project-card">
              <div className="project-title">
                <h4>{title}</h4>
                <a 
                  href={href}
                >
                  Website
                  <div className="project-img-container">
               
                  
                  <img src={image} className="project-img" alt={title} />
                
              </div>
                  </a>
              </div>
               
              
              
              <div className="project-info">
                <p>
                  {text}
                </p>
                <div className="project-footer">
                  <p>
                    {
                      codeHref && <a 
                        href={codeHref} 
                        className="nav-link"
                        target="_blank" 
                        rel="noreferrer"
                      >
                        Code
                      </a>
                    }
                  </p>
                </div>
              </div>
              
            </article>
            
                
          );
        })}

      </div>
    </section>
  )
}

export default Projects