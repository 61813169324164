 import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
 import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons';
 import { library } from '@fortawesome/fontawesome-svg-core';
 import ssria from '../../assets/Reference Centre_SSRIA.png';
 import recipe from '../../assets/mkline.org_Recipe_app_.png';
 import TAC from '../../assets/The_Abandoned_Castle.jpg';
 import dnd from '../../assets/dnd_app.jpg';
 import unity from '../../assets/toppng.com-game-controller-gamepad-icon-video-games-logo-691x766.png';

library.add( faGithub, faLinkedin)


export const links = [
  { id: 1, href: '#home', text: 'home' },
  { id: 2, href: '#about', text: 'about' },
  { id: 3, href: '#skills', text: 'skills' },
  { id: 4, href: '#projects', text: 'projects' },
  { id: 5, href: '#contact', text: 'contact' },
]


export const externalLinks = [
  { id: 1, href: 'https://github.com/mikekline?tab=repositories', icon: <FontAwesomeIcon icon='fa-brands fa-github' size='1x'/> },
  { id: 2, href: 'https://www.linkedin.com/in/klinem42/', icon: <FontAwesomeIcon icon="fa-brands fa-linkedin" size='1x' /> },
];

export const projects =[
  
  {id:1, title:'Recipe App (WIP)', image: recipe, href: 'https://www.mkline.org/Recipe_app/', codeHref:'https://github.com/mikekline/recipe_app_revised', text:'A work in progress recipe app where the data of recipes is uploaded and stored, sorted, be able to be changed and retrieved. JSON Web Token used for registration and login.' },
  {id:2, title:'SSRIA Reference Centre', image: ssria, href: 'https://www.ssria.ca/reference-centre', codeHref:'https://github.com/mikekline/SSRIA', text:'A Search Library created for my practicum using JavaScript, enabling the uploading and storage of files and data in a database. The library facilitates sorting and retrieval of information based on search parameters.'},
  {id:3, title:'The Abandoned Castle', image: TAC, href: 'https://www.mkline.org/school/the_abandoned_castle/index.html', codeHref:'https://github.com/mikekline/The-Abandoned-Castle', text:'My Final project for school. Based on text adventure games using JavaScript and jQuery to create/navigate “rooms”. It also includes functionality to, use, remove, and display an inventory. CSS Flexbox/Grid used for UI panel.' },
  {id:4, title:'A D&D App', image:dnd, href: 'https://www.mkline.org/school/js-assignment3/index.html', codeHref:'https://github.com/mikekline/Sait-Javascript-Assignment3', text:'A basic school project using Fetch to retrieve data from a REST API and display the data on a web page' },
  // {id:5, title:'Tutorials from Unity', image:'', href: '', codeHref:'', text:'' },
]