import React from 'react';
import Titles from './subComponents/Titles';
import portrait from '../assets/ME_mountain.jpg';

const About = () => {
  return (
    <section className="section" id="about">
      <Titles title='about' />

      <div className="section-center about-center">
       
        <article className="about-info">
          <h3>From Cook to Developer</h3>
          <p>
            After enjoying an extensive career in the Culinary Arts, ranging from the luxurious Rocky Mountaineer train to the scenic Moraine Lake Lodge, I have returned to my roots in all things technological, which began with my first computer when I was a kid. 
          </p>
          <p> 
           Taking part-time classes at the Southern Alberta Institute of Technology (SAIT) in HTML, CSS, JavaScript, and Photoshop, I then enrolled in a full-time certificate program in Web Development at SAIT. This allowed me to become familiar with various web technologies and understand the inner workings of webpages. That's when my eyes truly lit up.
          </p>
          <p>
            Working with coding languages like JavaScript feels akin to tinkering with a car engine or solving puzzles often found in video games. Figuring out and solving these puzzles or working on the mechanics gives me a great sense of satisfaction and a passion for working with code.
          </p>  
          <p>  
            As in cooking, I have discovered that coding requires a sense of perseverance and relentlessness, which can lead to 'ah-ha' moments and a great sense of accomplishment. This, coupled with the opportunity to contribute to the industry and witness the advancement of AI, makes me excited about working as a full-stack developer.
          </p>
        </article>
        <div className="about-img">
          <img
            src={portrait}
            className="about-photo"
            alt="Michael Kline"
            
          />
        </div>
      </div>
    </section>
  )
}

export default About