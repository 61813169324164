import html5 from '../../assets/technologies/html5.svg';
import css from '../../assets/technologies/css3.svg';
import js from '../../assets/technologies/javascript.svg';
import react from '../../assets/technologies/react.svg';
import node from '../../assets/technologies/nodejs.svg';
import mongodb from '../../assets/technologies/mongodb.svg';
import firebase from '../../assets/technologies/google-firebase.svg';
import ec2 from '../../assets/technologies/ec2-instance.svg';
import office from '../../assets/technologies/Office_Online-Logo.wine.svg';


export const technologies = [
  {id:1, image:html5, title:'HTML5'},
  {id:2, image:css, title:'CSS3'},
  {id:3, image:js, title:'JavaScript'},
  {id:4, image:react, title:'React'},
  {id:5, image:node, title:'Node.js'},
  {id:6, image:mongodb, title:'MongoDB'},
  {id:7, image:firebase, title:'Firebase'},
  {id:8, image:ec2, title:'EC2 Instance'},
  {id:9, image:office, title:'MS Office 365'},
]