import React from 'react';
import logo from '../assets/mountain_logo.png';
import {externalLinks} from './subComponents/Links';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import PageLinks from './subComponents/PageLinks';

library.add( faBars )



const Navbar = () => {
   const handleClick = (e) => {
    e.preventDefault();
    const links = document.getElementById('nav-links');
    links.classList.toggle('show-links');
  }


  return (
    <nav className="navbar">
      <div className="nav-center">
        <div className="nav-header">
          <a 
                href="#home" 
                className="nav-logo"
              >
            <img src={logo} className="nav-logo-img" alt="Mountains logo" />
          </a>
          <h3 className='nav-name'>michael Kline</h3>
          <button type="button" onClick={handleClick} className="nav-toggle" id="nav-toggle">
            <FontAwesomeIcon icon="fa-solid fa-bars" />
          </button>
        </div>
       
        <PageLinks parentClass='nav-links' itemClass='nav-link' />

        {/* <ul className="nav-icons">
            {externalLinks.map((link)=>{
              const {id, href, icon} = link;

              return (
                <li key={id}>
                  <a 
                    href={href} 
                    target="_blank" 
                    rel="noreferrer"
                    className="nav-icon"
                  >
                   {icon}
                  </a>
                </li>
              );
          })}
        </ul> */}

      </div>
    </nav>
  )
}

export default Navbar