import React from 'react'

const Titles = ({title}) => {
  return (
     <div className="section-title">
        <h2>{title}</h2>
      </div>
  )
}

export default Titles